<template>
    <div id="app">
        <Header ref="header"></Header>
        <Body ref="body"></Body>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Body from '@/components/Body.vue';

export default {
    name: 'App',
    components: {
        Header,
        Body
    }
}
</script>

<style>
#app {
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #353535;
    background: #ffffff;
}

.ly-text {
    margin: 0 auto;
}
</style>
