<template>
    <div class="px-lg-5 pb-5 overflow-hidden">
        <section ref="about-us" class="full-page header-section position-relative">
            <img alt="Bored Panda logo" class="logo mt-5" src="/images/boredpanda-logo.svg">
            <p class="py-5 px-3 m-0">
                <b>Bored Panda</b> is a leading publisher producing uplifting stories and videos
                that kill boredom at an unprecedented speed: <b>60B views per year</b>
            </p>
            <a class="arrow-down d-block" href="#" @click.prevent="scrollMeTo('about-us-info')">
                <img alt="Arrow down" src="/images/arrow-down.svg">
            </a>
        </section>

        <div class="container">
            <section ref="about-us-info" class="about-us-section">
                <div class="row">
                    <div data-aos="fade-right" class="col-6 pl-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                60B
                            </div>
                            <div class="info">
                                Video views per year on Facebook, YouTube, Instagram, TikTok and Snapchat
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" class="col-6 pr-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                231M
                            </div>
                            <div class="info">
                                Subscribers on social media platforms
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-right" class="col-6 pl-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                300+
                            </div>
                            <div class="info">
                                Social media channels
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" class="col-6 pr-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                160M+
                            </div>
                            <div class="info">
                                Monthly website pageviews
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-right" class="col-6 pl-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                16
                            </div>
                            <div class="info">
                                Languages
                            </div>
                        </div>
                    </div>
                    <div data-aos="fade-left" class="col-6 pr-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                600+
                            </div>
                            <div class="info">
                                Hard working pandas across the globe
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section ref="brands" class="brands-section">
                <h2 data-aos="zoom-in" class="py-5">Our brands include</h2>
                <div class="row">
                    <div data-aos="zoom-in-right" class="col-lg-4 col-6 pl-3 p-1 p-sm-3">
                        <div class="brand-block">
                            <div class="logo-block boredpanda">
                                <img alt="Bored Panda logo" class="logo" v-lazy="'/images/boredpanda-logo-invert.svg'">
                            </div>
                            <div class="name-block">
                                <h5>Bored Panda</h5>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" class="col-lg-4 col-6 pr-3 p-1 p-sm-3">
                        <div class="brand-block">
                            <div class="logo-block craftypanda">
                                <img alt="Crafty Panda logo" class="logo" v-lazy="'/images/crafty-logo.svg'">
                            </div>
                            <div class="name-block">
                                <h5>Crafty Panda</h5>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" class="col-lg-4 col-6 pl-3 p-1 p-sm-3">
                        <div class="brand-block">
                            <div class="logo-block handypanda">
                                <img alt="Handy Panda logo" class="logo" v-lazy="'/images/handy-panda-logo.svg'">
                            </div>
                            <div class="name-block">
                                <h5>Handy Panda</h5>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-right" class="col-lg-4 col-6 pr-3 p-1 p-sm-3">
                        <div class="brand-block">
                            <div class="logo-block ladypanda">
                                <img alt="Lady Panda logo" class="logo" v-lazy="'/images/lady-panda-logo.svg'">
                            </div>
                            <div class="name-block">
                                <h5>Lady Panda</h5>
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" class="col-lg-4 col-6 pl-3 p-1 p-sm-3">
                        <div class="brand-block">
                            <div class="logo-block hungrypanda">
                                <img alt="Hungry Panda logo" class="logo" v-lazy="'/images/hungry-panda-logo.svg'">
                            </div>
                            <div class="name-block">
                                <h5>Hungry Panda</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section ref="work-with-us" class="work-with-us-section">
                <h2 data-aos="zoom-in" class="py-5">We know how to help creators because we are a #1 creator</h2>
                <div class="row">
                    <div class="col-lg-12">
                        <div data-aos="zoom-in-right" class="panda-box">
                            <div class="panda"></div>
                        </div>
                    </div>
                    <div class="col-lg-12 pt-3">
                        <div data-aos="zoom-in-left" class="text large-text pt-2 pt-sm-0">
                            Bored Panda helps hundreds of <b>artists</b> and <b>video creators</b>
                            grow their audience and reach <b>millions</b> of bored pandas across social platforms.
                        </div>
                    </div>
                </div>

                <div data-aos="zoom-in-right" class="row pt-5">
                    <div class="col-12 text-sm-left">
                        <img class="img-fluid img-trophy d-inline-block pr-3" alt="Trophy"
                             v-lazy="'/images/ic-award_1-black.svg'">
                        <p class="d-inline-block align-content-center smaller-text text-trophy">
                            In 2017 Bored Panda was named #1 Facebook page in terms of engagements
                        </p>
                    </div>
                </div>
                <div data-aos="zoom-in-left" class="row pt-3">
                    <div class="col-12 text-sm-left">
                        <img class="img-fluid img-trophy d-inline-block pr-3" alt="Medal" v-lazy="'/images/ic-award_2-black.svg'">

                        <p class="d-inline-block align-content-center smaller-text text-trophy">
                            In 2020 Crafty Panda became a #1 Facebook page in terms of video views
                        </p>
                    </div>
                </div>

                <h2 data-aos="zoom-in" class="py-5">What creators are saying</h2>

                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <div data-aos="zoom-in" class="story-item">
                            <div class="description-block">
                                <div class="author clearfix">
                                    <a target="_blank"
                                       href="https://www.boredpanda.com/polymer-clay-book-covers-part2-mandarin-duck-aniko-kolesnikova/">
                                        <img class="author-icon"
                                             v-lazy="'https://static.boredpanda.com/blog/wp-content/uploads/2016/03/399479_328478450580551_2011221819_n.jpg'"
                                             alt="Aniko Kolesnikova">
                                        <span class="author-title">
                                    <span class="author-name">Aniko Kolesnikova</span>
                                    <span class="author-description">Artist, Latvia</span>
                                </span>
                                    </a>
                                </div>
                                <div class="author-quote">
                                    <i>
                                        "Literally in 1 day I gained thousands and thousands of new likes,
                                        interviews, reposts and in 1-2 weeks’ time I was booked for the next 6 months
                                        upfront."
                                    </i>
                                    <a target="_blank" class="author-interview-link"
                                       href="https://www.boredpanda.com/polymer-clay-book-covers-part2-mandarin-duck-aniko-kolesnikova/">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 pt-4 pr-lg-3">
                        <div data-aos="zoom-in-right" class="story-item">
                            <div class="description-block">
                                <div class="author clearfix">
                                    <a target="_blank" href="https://www.boredpanda.com/author/mikaeltheim/">
                                        <img class="author-icon" alt="Mikaël Theimer"
                                             v-lazy="'https://static.boredpanda.com/blog/wp-content/uploads/2015/12/MG_4731_12-151.jpg'">
                                        <span class="author-title">
                                        <span class="author-name">Mikaël Theimer</span>
                                        <span class="author-description">Photographer, Canada</span>
                                    </span>
                                    </a>
                                </div>
                                <div class="author-quote">
                                    <i>
                                        "In just a couple of weeks my Facebook page went from 800 likes to 8000."</i>
                                    <a target="_blank" class="author-interview-link"
                                       href="https://www.boredpanda.com/how-bored-panda-helped-promote-my-photography-mikael-theimer/">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 pt-4">
                        <div data-aos="zoom-in-left" class="story-item">
                            <div class="description-block">
                                <div class="author clearfix">
                                    <a target="_blank"
                                       href="https://www.boredpanda.com/author/ioyukari/">
                                        <img class="author-icon" alt="Lingvistov"
                                             v-lazy="'https://static.boredpanda.com/blog/wp-content/uploads/2016/01/lingvistov-profile.png'">
                                        <span class="author-title">
                                        <span class="author-name">Lingvistov</span>
                                        <span class="author-description">Illustrator, Russia</span>
                                    </span>
                                    </a>
                                </div>
                                <div class="author-quote">
                                    <i>
                                        "After posting my illustrations here, we went from 2-3 monthly orders to
                                        150."</i>
                                    <a target="_blank" class="author-interview-link"
                                       href="https://www.boredpanda.com/how-boredpanda-helped-me-turn-hobby-into-job/">
                                        Read More
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section ref="achievements" class="achievements-section">
                <h2 data-aos="zoom-in" class="py-5">We are the largest video production studio in our region</h2>
                <div class="row m-0">
                    <div data-aos="zoom-in-right" class="col-12 p-0">
                        <img class="img-fluid" alt="Studio" :src="'/images/studio.jpg'">
                    </div>
                    <div data-aos="zoom-in-left" class="col-12 p-0">
                        <p class="text large-text pt-3 text-left">
                            Bored Panda Studios produce award-winning original shows that reach hundreds of
                            millions of views every month.
                        </p>
                    </div>
                </div>
                <div class="row pt-3">
                    <div data-aos="zoom-in-right" class="col-6 pl-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                120
                            </div>
                            <div class="info">
                                Videos per month
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" class="col-6 pr-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                59,200
                            </div>
                            <div class="info">
                                Sq. ft combined (4 production studios)
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-right" class="col-6 pl-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                7
                            </div>
                            <div class="info">
                                Hours of original content per month
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in-left" class="col-6 pl-3 p-1 p-sm-3">
                        <div class="info-block">
                            <div class="title">
                                16
                            </div>
                            <div class="info">
                                Languages
                            </div>
                        </div>
                    </div>
                </div>

                <h2 data-aos="zoom-in" class="py-5">Let's have a walkthrough of our studio</h2>

                <div class="row">
                    <div data-aos="zoom-in" class="col-12">
                        <LazyYoutube :src="'https://www.youtube.com/embed/EwNDiaFds9k'" allowfullscreen
                                     max-width="1100px" aspect-ratio="16:9" thumbnail-quality="maxres">
                        </LazyYoutube>
                    </div>
                </div>

                <div class="row pt-5">
                    <div class="col-12 text-sm-left">
                        <div data-aos="zoom-in-right">
                            <img class="img-fluid img-trophy d-inline-block pr-3" alt="Gold"
                                 v-lazy="'/images/star.svg'">
                            <div class="d-inline-block align-content-center smaller-text text-trophy">
                                10 Gold YouTube creator awards that are awarded to channels that surpass
                                1 million subscribers
                            </div>
                        </div>
                        <div data-aos="zoom-in-left" class="pt-3">
                            <img class="img-fluid img-trophy d-inline-block pr-3" alt="Diamond"
                                 v-lazy="'/images/diamond.svg'">

                            <div class="d-inline-block align-content-center smaller-text text-trophy">
                                1 Diamond YouTube creator award that is awarded to channels that surpass
                                10 million subscribers
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row pt-5">
                    <div data-aos="zoom-in" class="col-12">
                        <img class="img-fluid full-width" alt="Rewards" :src="'/images/bps-rewards.jpg'">
                    </div>
                </div>
            </section>

            <section>
                <h2 data-aos="zoom-in" class="py-5">For video creators</h2>
                <div class="row">
                    <div data-aos="zoom-in-right" class="col-12">
                        <div class="image video-editor-panda"></div>
                    </div>
                    <div data-aos="zoom-in-left" class="col-12">
                        <p class="creators-text large-text pt-3 text-left">
                            Reaching success on one social platform is hard, but reaching success on multiple platforms
                            is nearly impossible on your own. We have an in-depth knowledge of each platform.
                            With our <b>next-gen technology, A/B testing</b> and <b>editing</b> we will help you reach
                            huge audiences on social platforms you're less familiar with.
                        </p>

                        <div class="text-center">
                            <a href="https://partnership.boredpanda.com/home" target="_blank"
                               class="btn btn-lg btn-round btn-success">
                                Contact us
                            </a>
                        </div>
                    </div>
                </div>

                <h2 data-aos="zoom-in" class="py-5">For other creators</h2>
                <div class="row">
                    <div data-aos="zoom-in-right" class="col-12">
                        <div class="image crafty-panda"></div>
                    </div>
                    <div data-aos="zoom-in-left" class="col-12">
                        <p class="creators-text pt-3 text-left large-text">
                            Boredpanda.com is the leading story publishing platform for creative people who want to
                            become popular. 100M pandas read our articles every month, and our best stories are
                            reposted by big publishers such as HuffPost, Buzzfeed, Mashable, Daily Mail
                            and many others.
                        </p>

                        <div class="text-center">
                            <a href="https://www.boredpanda.com/add-new-post/"
                               target="_blank" class="btn btn-lg btn-round btn-success">
                                Add post
                            </a>
                        </div>
                    </div>
                </div>

                <h2 data-aos="zoom-in" class="py-5">Exclusive partnerships</h2>

                <div data-aos="zoom-in" class="row">
                    <div class="col-12">
                        <p class="creators-text text-left large-text m-0">
                            We promote 300 artists per month by giving them a multimillion viewer audience they deserve
                        </p>
                    </div>
                </div>
                <div class="row pt-3">
                    <div data-aos="zoom-in" class="col-sm-4 col-12 p-3">
                        <div class="partnership-box">
                            <div class="logo chester">
                                <img alt="Chester" v-lazy="'/images/chester-white.png'">
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" class="col-sm-4 col-12 p-3">
                        <div class="partnership-box">
                            <div class="logo totally-handy">
                                <img alt="Totally Handy" v-lazy="'/images/totally-handy.svg'">
                            </div>
                        </div>
                    </div>
                    <div data-aos="zoom-in" class="col-sm-4 col-12 p-3">
                        <div class="partnership-box">
                            <div class="logo well-done-tips">
                                <img alt="Well Done Tips" v-lazy="'/images/well-done-tips.png'">
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section ref="career" class="career-section">
                <h2 data-aos="zoom-in" class="py-5">Join the team</h2>
                <div class="row m-0">
                    <div data-aos="zoom-in" class="col-sm-12 p-0">
                        <img class="img-fluid" alt="Jobs about" v-lazy="'/images/jobs-about.jpg'">
                    </div>
                    <div data-aos="zoom-in" class="col-sm-12 p-0 pt-3 text-justify">
                        <p class="text large-text">
                            Bored Panda is fighting boredom from their headquarters in Vilnius, Lithuania.
                            We’re looking for extraordinary people with social media, copywriting, video,
                            marketing and engineering experience who want to play an active role in a
                            fast-growing company and have a direct impact on its success.
                        </p>
                    </div>
                </div>
                <div class="row pt-4 text-left text-sm-center">
                    <div data-aos="zoom-in" class="col-sm-3">
                        <img class="img-fluid" alt="Jobs competitive" v-lazy="'/images/jobs-competitive.jpg'">
                        <h4 class="title pt-3">Competitive Terms</h4>
                        <p class="text">
                            You’ll get a competitive salary, work equipment,
                            healthy snacks and advancement opportunities
                        </p>
                    </div>
                    <div data-aos="zoom-in" class="col-sm-3">
                        <img class="img-fluid" alt="Jobs playful balance" v-lazy="'/images/jobs-playful.jpg'">
                        <h4 class="title pt-3">Playful Balance</h4>
                        <p class="text">
                            Work Hard, Play Hard! We have foosball, playstation,
                            table tennis and more to relax together
                        </p>
                    </div>
                    <div data-aos="zoom-in" class="col-sm-3">
                        <img class="img-fluid" alt="Jobs pet friendly" v-lazy="'/images/jobs-pets.jpg'">
                        <h4 class="title pt-3">Pet Friendly</h4>
                        <p class="text">
                            Do you know that having pets in the office can calm people and
                            help them focus better? We do
                        </p>
                    </div>
                    <div data-aos="zoom-in" class="col-sm-3">
                        <img class="img-fluid" alt="Jobs curious" v-lazy="'/images/jobs-curious.jpg'">
                        <h4 class="title pt-3">Curious</h4>
                        <p class="text">
                            We ask questions and seek answers, we are always searching
                            for new ways to grow and entertain
                        </p>
                    </div>
                </div>
                <div class="text-center py-2">
                    <a href="https://jobs.boredpanda.com/#openpositions" target="_blank"
                       class="btn btn-round btn-success">
                        See open positions
                    </a>
                </div>

            </section>

            <section ref="contacts" class="full-page">
                <h2 data-aos="zoom-in" class="py-5">Contact us</h2>

                <div class="subjects-list row">
                    <div class="subject pb-3 text-center col-lg-4 col-md-6 col-12" v-for="subject in subjects"
                         v-bind:key="subject.email">
                        <button class="btn btn-outline-success btn-lg w-100"
                                :class="{'active': contact.subject === subject}" @click="processSubject(subject)"
                        >{{ subject.name }}
                        </button>
                    </div>
                </div>

                <div ref="contact-form" class="pt-3">
                    <div v-if="contact.subject.type === 'video-partnership'">
                        <p class="creators-text large-text pt-3 text-left">
                            Reaching success on one social platform is hard, but reaching success on multiple platforms
                            is nearly impossible on your own. We have an in-depth knowledge of each platform.
                            With our <b>next-gen technology, A/B testing</b> and <b>editing</b> we will help you reach
                            huge audiences on social platforms you're less familiar with.
                        </p>

                        <div class="text-center">
                            <a href="https://partnership.boredpanda.com/home" target="_blank"
                               class="btn btn-lg btn-round btn-success">
                                Contact us
                            </a>
                        </div>
                    </div>
                    <b-alert v-model="success" variant="success">Your message was successfully sent!</b-alert>
                    <b-alert v-model="error" variant="danger">Error! Please try again!</b-alert>
                    <form class="form text-left" @submit.prevent="submitForm"
                          v-if="contact.subject.type === 'contact-form'">
                        <div class="row">
                            <div class="col-lg-6 col-12">
                                <div class="form-group">
                                    <label>Your Name</label>
                                    <input type="text" required v-model="contact.name"
                                           class="form-control form-control-lg">
                                </div>
                                <div class="form-group">
                                    <label>Your Email</label>
                                    <input type="email" required v-model="contact.email"
                                           class="form-control form-control-lg">
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Message</label>
                                    <textarea rows="10" v-model="contact.message" required
                                              class="form-control form-control-lg">
                                </textarea>
                                </div>
                                <div class="form-group">
                                    <vue-recaptcha sitekey="6LfHzU4cAAAAACs_DROK_JD4U3H1HgDvOsOjIUFE"
                                                   ref="recaptcha"
                                                   @verify="onVerify"
                                                   @expired="onExpired"
                                                   :loadRecaptchaScript="true">
                                    </vue-recaptcha>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-lg btn-rounded btn-success"
                                            :disabled="sendingMail" v-text="sendingMail ? 'Sending...' : 'Send'">
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

        </div>
    </div>
</template>

<script>

import scroll from '@/mixins/scroll';
import {LazyYoutube} from 'vue-lazytube';
import VueRecaptcha from 'vue-recaptcha';
import Amplify, {API} from 'aws-amplify';
import awsconfig from '@/aws-exports';
import {BAlert} from 'bootstrap-vue';

Amplify.configure(awsconfig);

export default {
    name: 'Body',
    mixins: [scroll],
    components: {LazyYoutube, VueRecaptcha, BAlert},
    data() {
        return {
            contact: {
                name: null,
                email: null,
                message: null,
                subject: '',
                recaptcha: null,
            },
            sendingMail: false,
            success: false,
            error: false,
            subjects: [
                {name: 'Advertising', email: 'justina@boredpanda.com', type: 'contact-form'},
                {name: 'Business inquiries', email: 'tomas.banisauskas@gmail.com', type: 'contact-form'},
                {name: 'Article submissions', email: 'community@boredpanda.com', type: 'contact-form'},
                {name: 'Video partnerships', email: '', type: 'video-partnership'},
                {name: 'Media inquiries', email: 'tomas.banisauskas@gmail.com', type: 'contact-form'},
                {name: 'Careers', email: 'jobs@boredpanda.com', type: 'contact-form'},
            ]
        }
    },
    methods: {
        async submitForm() {
            this.sendingMail = true;
            API.post('nodeapi', '/email/send', {
                body: {
                    email: this.contact.subject.email,
                    subject: this.contact.subject.name,
                    message: this.getMessage(),
                    recaptcha: this.contact.recaptcha,
                },
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then(res => {
                if (res[0].statusCode === 202) {
                    this.contact.email = null;
                    this.contact.name = null;
                    this.contact.message = null;
                    this.contact.subject = '';
                    this.success = true;
                    this.error = false;
                    this.sendingMail = false;
                } else {
                    this.success = false;
                    this.error = true;
                    this.sendingMail = false;
                }
            }).catch(e => {
                console.error(e);
                this.error = true;
                this.success = false;
                this.sendingMail = false;
            });
        },
        getMessage() {
            return "Name: " + this.contact.name + "\n\n"
                + "Email: " + this.contact.email + "\n\n"
                + "Message: " + this.contact.message;
        },
        onVerify(response) {
            this.contact.recaptcha = response;
        },
        onExpired() {
            this.$refs.recaptcha.reset();
        },
        async processSubject(subject) {
            this.contact.subject = subject;
            await this.$nextTick();
            this.scrollMeTo('contact-form');
        },
    }
}
</script>

<style scoped lang="scss">
.full-page {
    min-height: calc(100vh - 56px);
}

h2 {
    font-size: 2.5rem;
    @media (max-width: 480px) {
        font-size: 2rem;
    }
}

h4 {
    font-size: 1.3rem;
}

.full-width {
    width: 100%;
}

p, div {
    font-size: 20px;
    @media (max-width: 768px) {
        font-size: 16px;
    }

    &.large-text {
        font-size: 30px;
        @media (max-width: 768px) {
            font-size: 20px;
        }
    }
}

.btn-success {
    background: #54b960;
    padding: .5rem 3rem;

    &:hover {
        background-color: #218838;
        border-color: #1e7e34;
    }
}

.success-link {
    color: #54b960;
}

.header-section {
    .logo {
        height: 15%;
    }

    p {
        font-size: 3.5vw;
        @media (max-width: 768px) {
            font-size: 5.5vw;
        }
    }

    .arrow-down {
        position: absolute;
        bottom: 110px;
        left: 0;
        right: 0;
        width: 70px;
        margin: 0 auto;
        @media (max-width: 480px) {
            width: 35px;
        }
    }

    @media (max-width: 991px) and (orientation: landscape) {
        p {
            padding-top: 1rem !important;
        }
        .logo {
            margin-top: 1rem !important;
        }
    }
}


.info-block {
    background: #54b960;
    color: #fff;
    min-height: 220px;
    margin: 0 auto;
    padding: 20px;

    .title {
        font-size: 70px;
        height: 125px;
        line-height: 125px;
        font-weight: bold;
        @media (max-width: 768px) {
            font-size: 35px;
            height: 60px;
            line-height: 60px;
        }
    }

    .info {
        font-size: 25px;
        min-height: 110px;
        font-weight: 400;
        padding: 0 45px;
        box-sizing: border-box;
        @media (max-width: 992px) {
            padding: 0;
            font-size: 18px;
        }
        @media (max-width: 420px) {
            padding: 0;
            font-size: 16px;
        }
    }
}

.brands-section {
    .brand-block {
        margin: 0 auto;
        padding-bottom: 20px;

        .logo-block {
            height: 280px;

            .logo {
                height: 150px;
                margin-top: 65px;
            }

            &.boredpanda {
                background: #54b960;
            }

            &.craftypanda {
                background: #ffc60c;
            }

            &.kaboom {
                background: #ff6496;
            }

            &.gotcha {
                background: #ff7dbf;
            }

            &.littlepanda {
                background: #00aeef;
            }

            &.handypanda {
                background: #ffb40d;
            }

            &.ladypanda {
                background: #ff40be;
            }

            &.hungrypanda {
                background: #ff77a0;
            }

            &.zoom {
                background: #9FEB00;
            }
        }

        .name-block {
            text-align: left;

            h5 {
                font-weight: bold;
                font-size: 25px;
                margin-top: 10px;
            }
        }

        @media (max-width: 768px) {
            .logo-block {
                height: 150px;

                .logo {
                    height: 100px;
                    margin-top: 25px;
                }
            }

            .name-block {
                h5 {
                    font-size: 20px;
                }
            }
        }
    }
}

.img-trophy {
    width: 120px;
}

.text-trophy {
    width: calc(100% - 120px);
    vertical-align: middle;
    margin: 0;
}

@media (max-width: 480px) {
    .img-trophy {
        width: 60px;
    }
    .text-trophy {
        width: calc(100% - 60px);
    }
}

.work-with-us-section {
    .panda-box {
        background: #363946;
        padding: 50px 0 0;

        .panda {
            background: url(/images/panda-winner.png) no-repeat;
            background-size: 300px auto;
            width: 300px;
            height: 285px;
            display: block;
            margin: 0 auto;

            @media (max-width: 450px) {
                background-size: 200px auto;
                height: 200px;
                width: 200px;
            }
        }

    }

    .text {
        text-align: left;
    }

    .smaller-text {
        text-align: left;
    }

    .story-item {
        position: relative;
        width: 100%;
        background-color: #ffffff;
        -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);

        .description-block {
            padding: 25px;
            box-sizing: border-box;
            @media(max-width: 480px) {
                padding: 20px;
            }

            .author {
                a:hover {
                    .author-name {
                        color: #363946;
                    }
                }

                .author-icon {
                    display: block;
                    float: left;
                    border-radius: 50%;
                    width: 40px;
                    height: 40px;
                }

                .author-title {
                    float: left;
                    display: block;
                    margin-left: 10px;
                    margin-top: 2px;
                }

                .author-name {
                    display: block;
                    line-height: 18px;
                    font-size: 16px;
                    color: #353535;
                    font-weight: 600;
                    text-align: left;
                }

                .author-description {
                    display: block;
                    font-size: 14px;
                    color: #a0a0a0;
                    text-align: left;
                }
            }

            .author-quote {
                font-family: Merriweather, sans-serif;
                font-weight: bold;
                font-size: 24px;
                line-height: 32px;
                color: #363946;
                margin: 20px 0 0;
                text-align: left;

                i {
                    font-size: 24px;
                }
            }

            .author-interview-link {
                display: block;
                width: 95px;
                height: 34px;
                line-height: 30px;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                color: #56b863;
                border: 2px solid #56b863;
                background: #fff;
                margin-top: 10px;
                font-family: Roboto, sans-serif;
                -webkit-border-radius: 18px;
                -moz-border-radius: 18px;
                -ms-border-radius: 18px;
                -o-border-radius: 18px;
                border-radius: 18px;
            }
        }
    }
}

.partnership-box {
    height: 150px;
    background: #000;
    overflow: hidden;

    .logo {
        line-height: 150px;
        font-size: 30px;

        &.totally-handy {
            background: #FF342D;
        }

        &.well-done-tips img {
            padding: 15px;
        }

        img {
            height: 150px;
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.btn-round {
    border-radius: 50px;
    padding: 18px 25px;
    font-size: 22px;
    font-weight: 600;
    min-width: 220px;
}

.subject {
    .btn {
        width: 300px;
    }
}

.image {
    width: 100%;
    height: 400px;
    display: block;
    margin: 0 auto;

    @media (max-width: 450px) {
        height: 300px;
    }

    &.crafty-panda {
        background: url(/images/bp-crafty.svg) no-repeat center center;
        background-size: cover;
    }

    &.video-editor-panda {
        background: url(/images/bp-video-editor.svg) no-repeat center center;
        background-size: cover;
    }
}

.cWExZE .ly-text {
    width: 100%;
}
</style>
