<template>
    <b-navbar ref="navbar" toggleable="lg" type="light" variant="light" :sticky="true">

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" class="justify-content-center" is-nav>
            <b-navbar-nav>
                <b-nav-item href="#" @click.prevent="scrollMeTo('about-us')">About us</b-nav-item>
                <b-nav-item href="#" @click.prevent="scrollMeTo('brands')">Our brands</b-nav-item>
                <b-nav-item href="#" @click.prevent="scrollMeTo('work-with-us')">Work with us</b-nav-item>
                <b-nav-item href="#" @click.prevent="scrollMeTo('achievements')">Achievements</b-nav-item>
                <b-nav-item href="#" @click.prevent="scrollMeTo('career')">Career</b-nav-item>
                <b-nav-item href="#" @click.prevent="scrollMeTo('contacts')">Contact us</b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>

import scroll from '@/mixins/scroll';

export default {
    name: 'Header',
    mixins: [scroll],
}
</script>

<style scoped>
</style>
