export default {
    methods: {
        scrollMeTo(ref) {
            const element = this.$parent.$refs.body.$refs[ref];
            const headerOffset = this.$parent.$refs.header.$refs.navbar.$el.offsetHeight;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition - headerOffset + window.pageYOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        },
    }
}