import Vue from 'vue';
import App from './App.vue';
import {NavbarPlugin} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.min.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueLazyload from 'vue-lazyload';

Vue.config.productionTip = false

Vue.use(NavbarPlugin);
Vue.use(VueLazyload, {
    preLoad: 2,
});

new Vue({
    created() {
        AOS.init()
    },
    render: h => h(App),
}).$mount('#app')
